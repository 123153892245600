import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import Head from 'next/head';
import Router from 'next/router';
import { ReactElement, ReactNode, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import FontPreload from '@components/Font/FontPreload';
import GoogleAnalyticsScript from '@components/GoogleAnalyticsScript';
import MicrosoftClarityScript from '@components/MicrosoftClarityScript';
import ToastNotifications from '@components/Notifications/ToastNotifications';
import OrganicSDKCodeSnippet from '@components/OrganicSDKCodeSnippet';
import SessionProvider from '@components/SessionProvider';
import { sendPageview } from '@helpers/analytics';
import { MUI_LICENSE_KEY } from '@helpers/constants';
import { createEmotionCache } from '@helpers/emotion';
import { getDefaultSession } from '@helpers/session';
import { theme } from '@themes/default';

import type { EmotionCache } from '@emotion/cache';
import type { AppProps } from 'next/app';

// Setting MUI license here once for the whole application
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 1000 * 60 * 5, // in milliseconds, 5 minutes
    },
  },
});

const clientSideEmotionCache = createEmotionCache();

type ComponentWithLayout = AppProps['Component'] & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type OrganicAppProps = AppProps & {
  Component: ComponentWithLayout;
  emotionCache: EmotionCache;
};

const OrganicApp = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: OrganicAppProps) => {
  const defaultSession = getDefaultSession();
  const {
    activeOrganizationGuid = defaultSession.activeOrganizationGuid,
    organizations = defaultSession.organizations,
    permissions = defaultSession.permissions,
    user = defaultSession.user,
    ...cleanedPageProps
  } = pageProps;

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    const onRouteChange = (pathname: string) => {
      sendPageview(pathname, user.guid);
    };
    Router.events.on('routeChangeComplete', onRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', onRouteChange);
    };
  }, [user.guid]);

  useEffect(() => {
    // remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={emotionCache}>
        <SessionProvider
          activeOrganizationGuid={activeOrganizationGuid}
          organizations={organizations}
          permissions={permissions}
          user={user}
        >
          <Head>
            <title>Welcome to Organic!</title>
            <meta
              content="initial-scale=1, width=device-width"
              name="viewport"
            />
            <FontPreload />
          </Head>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {getLayout(<Component {...cleanedPageProps} />)}
            <ToastNotifications />
          </ThemeProvider>
        </SessionProvider>
      </CacheProvider>
      <GoogleAnalyticsScript userGuid={user.guid} />
      <MicrosoftClarityScript userGuid={user.guid} />
      <OrganicSDKCodeSnippet />
      <ReactQueryDevtools position="bottom-left" />
    </QueryClientProvider>
  );
};

export default OrganicApp;
