import { kebabCase, mapKeys } from 'lodash';

export const unicodeRange =
  'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';

export type FontDeclaration = {
  fontFamily: string;
  fontStyle: string;
  fontDisplay: string;
  fontWeight: string | number;
  unicodeRange?: typeof unicodeRange;
  fontNamedInstance?: string;
  src: string;
};

export type FontConfig = {
  fontFamily: string;
  fontFaceConfig: FontDeclaration[];
  fontFace?: FontDeclaration[];
};

/**
 * The actual declaration value used for @font-face
 * Each individual font source file is joined separately to allow access to
 * the structured source files for access elsewhere (e.g. in preload <link /> tags)
 */
export const generateFontFace = (fontFaceConfig: FontDeclaration[]) => {
  return fontFaceConfig.map((props) => {
    const src = `url("${props.src}") format("woff2")`;

    return {
      ...props,
      src,
    };
  });
};

// generates a string version of the @font-face CSS at-rule
export const generateFontFaceRule = (fontFace: FontDeclaration[]) => {
  // convert fontFace keys to kebab-case and build style string
  const fontFaceKebab = fontFace.map((f) => mapKeys(f, (v, k) => kebabCase(k)));
  let fontFaceString = '';

  // convert each ruleset into a CSS string
  fontFaceKebab.forEach((style) => {
    fontFaceString += `@font-face{${Object.entries(style)
      .map(([k, v]) => `${k}:${v}`)
      .join(';')}}`;
  });

  return fontFaceString;
};
