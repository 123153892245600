// Whether this is the browser (client)
const isBrowser = typeof window !== 'undefined';
// Whether this is the server (NodeJS)
const isSSR = !isBrowser;

declare global {
  interface Window {
    SENTRY_RELEASE?: {
      id: string;
    };
  }
}
const getRelease = () =>
  (isBrowser ? window.SENTRY_RELEASE?.id : process.env.SENTRY_RELEASE) || '0';

// Checks for environments the code runs in
const isDevelopment = process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev';
const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';
const isStaging = process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging';
const isTest = process.env.NEXT_PUBLIC_ENVIRONMENT === 'test';
// eslint-disable-next-line no-underscore-dangle
const isTestingInSelenium =
  isBrowser && parseInt(String(document.__api_port) || '0') > 8000;
const isTestingInJest = process.env.JEST_WORKER_ID !== undefined;
const isRunningTest = isTest || isTestingInSelenium || isTestingInJest;

export {
  isRunningTest,
  isTestingInJest,
  isTestingInSelenium,
  isTest,
  isStaging,
  isProduction,
  isDevelopment,
  getRelease,
  isSSR,
  isBrowser,
};
