/* eslint-disable no-var */
// note: we allow var below because we want to mirror the code snippet in our docs precisely
import { useEffect } from 'react';

const GoogleAnalyticsScript = () => {
  useEffect(() => {
    window.organic = window.organic || {};
    // @todo revise this to the actual USP cookie once one exists
    window.__organic_usp_cookie = 'YOUR_USP_COOKIE_NAME'; // update this to your USP cookie name
    window.loadOrganicCSS =
      window.loadOrganicCSS ||
      function (id, src) {
        if (document.getElementById(id)) return;
        var link = document.createElement('link');
        link.id = id;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = src;
        document.getElementsByTagName('head')[0].appendChild(link);
      };
    window.loadOrganicScript =
      window.loadOrganicScript ||
      function (id, src, moduleSrc) {
        if (document.getElementById(id)) return;
        var script = document.createElement('script');
        script.id = id;
        script.async = true;

        var useModules = typeof script.noModule === 'boolean' && moduleSrc;
        if (useModules) {
          // If js-module provided and browser supports it
          script.type = 'module';
          script.src = moduleSrc;
        } else {
          // Using regular script
          script.src = src;
        }
        document.getElementsByTagName('head')[0].appendChild(script);
      };
    window.loadOrganicCSS(
      'organic-css',
      'https://api.organiccdn.io/sdk/customcss/48e810a7-a2ce-40a4-9848-82b921814884',
    );
    window.loadOrganicScript(
      'organic-sdk',
      'https://organiccdn.io/assets/sdk/sdkv2?guid=48e810a7-a2ce-40a4-9848-82b921814884',
      'https://organiccdn.io/assets/sdk/sdkv2?guid=48e810a7-a2ce-40a4-9848-82b921814884&usemodules=true',
    );
  }, []);
  return null;
};

export default GoogleAnalyticsScript;
