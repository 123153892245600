import { GraphQLClient } from 'graphql-request';
import { NextApiResponse } from 'next';

import { isDevelopment, isSSR, isTest } from '../utils';

import type { IncomingMessage } from 'http';

declare global {
  interface Document {
    __api_port?: string | number;
  }
}

const getApiProtocolAndPort = (req?: IncomingMessage) => {
  let port = 0;
  if (isDevelopment || isTest) {
    /* We don't have access to the req variable on the client, thus read
       the port from the injected variable. */

    // eslint-disable-next-line no-underscore-dangle
    port = parseInt(
      String(req ? req.headers['x-server-port'] || 0 : document.__api_port),
      10,
    );
  }
  const isTestPort = port > 8000; // The parallel selenium test run on 8001-800x ports

  const devAndTestConfig = {
    protocol: 'https',
    port: isTestPort ? port : '',
  };
  const devAndTestSSRConfig = {
    protocol: 'http',
    port: isTestPort ? port : 8000,
  };
  const environmentConfig = {
    dev: devAndTestConfig,
    devSSR: devAndTestSSRConfig,
    staging: {
      protocol: 'https',
      port: '',
    },
    test: devAndTestConfig,
    testSSR: devAndTestSSRConfig,
    production: {
      protocol: 'https',
      port: '',
    },
  };
  let env = process.env.NEXT_PUBLIC_ENVIRONMENT as
    | 'dev'
    | 'staging'
    | 'test'
    | 'production'
    | 'devSSR'
    | 'testSSR';
  env =
    isSSR && (isDevelopment || isTest)
      ? (`${env}SSR` as 'devSSR' | 'testSSR')
      : env;
  return environmentConfig[env];
};

const getAPIurl = (req?: IncomingMessage) => {
  const { protocol, port } = getApiProtocolAndPort(req);
  return `${protocol}://${process.env.NEXT_PUBLIC_API_DOMAIN}${
    port ? `:${port}` : ''
  }/graphql`;
};

const getRESTapiURL = (req?: IncomingMessage) => {
  const { protocol, port } = getApiProtocolAndPort(req);
  return `${protocol}://${process.env.NEXT_PUBLIC_API_DOMAIN}${
    port ? `:${port}` : ''
  }`;
};

export const getApiClient = (
  req?: IncomingMessage,
  signal?: AbortSignal,
  isHasura?: boolean,
) =>
  new GraphQLClient(
    isHasura
      ? `https://${process.env.HASURA_API_DOMAIN}/v1/graphql`
      : getAPIurl(req),
    {
      // allow passing cookie to the api.* subdomain
      credentials: 'include',
      // @ts-expect-error FIXME: there are incompatible AbortSignal signatures (dom and node)
      signal,
    },
  );

/* Any Next's API endpoint must set this header */
const setupApiResponseHeaders = (res: NextApiResponse) => {
  res.setHeader('X-Robots-Tag', 'noindex, nofollow');
};

/* Helper to extract django API cookies for fetching data on server-side */
const getApiCookies = (req: IncomingMessage) => {
  const cookies = (req.headers.cookie || '')
    .split(';')
    .map((v) => v.trim())
    .filter((v) => v.startsWith('csrftoken') || v.startsWith('sessionid'));
  return cookies.join(';');
};

/* Helper to set up additional cookies in Next's response */
const addCookies = (res: NextApiResponse, cookies: string[]) => {
  const existingCookies = (res.getHeader('set-cookie') as any[]) || [];
  res.setHeader('set-cookie', [...existingCookies, ...cookies]);
};

export {
  addCookies,
  getApiCookies,
  setupApiResponseHeaders,
  getRESTapiURL,
  getAPIurl,
  getApiProtocolAndPort,
};
