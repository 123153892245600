import { getStableEmptyArray } from '../collections';

type Edge = { node?: unknown };
type Edges = { edges?: Edge[] };
export type ExtractNode<T extends Edges> = InferElement<T['edges']>['node'];

export const extractNodes = <
  T extends Edges,
  R extends ExtractNode<T> = ExtractNode<T>,
>(
  root?: T,
) => (root?.edges?.map((e) => e.node) || (getStableEmptyArray() as R[])) as R[];
