import { parseISO } from 'date-fns';
import { IncomingMessage } from 'http';
import { NextRouter } from 'next/router';
import { ParsedQuery, parseUrl, stringifyUrl } from 'query-string';

import { QueryStringArrayFormat } from './constants';
import { ssrLocalStorage, withSsrSession } from './server';
import { isGuidString, isDateString } from './typeGuards';
import { isSSR } from './utils';
import { PDFConverterProps } from '../pages/api/report/get-pdf';

// @todo move these into Dashboard/helpers/urls
const homeURL = '/';
const forbiddenURL = '/forbidden';
const signInURL = '/user/signin';
const resetPasswordURL = '/user/reset-password';
const inviteURL = '/user/invite';
const confirmURL = '/user/confirm';
const selectOrganizationURL = '/user/select-organization';

/* API routes */
const apiURLs = {
  pdfExport: '/api/report/get-pdf',
  signin: '/api/signin',
  signinGoogle: '/api/signin-google',
  signout: '/api/signout',
  session: '/api/session',
  /* Django API urls, used primarily for redirects */
  djangoEmailConfirmation: '/user/confirm',
  sdkConfigURL: '/sdkv2/config/',
};

const loginWithReturnHere = (req?: IncomingMessage) => {
  let redirectTo;
  if (isSSR && req) {
    const url = req.url || homeURL;
    if (req.headers.host && url.includes(req.headers.host)) {
      // eslint-disable-next-line prefer-destructuring
      redirectTo = url.split(req.headers.host)[1];
    } else {
      redirectTo = url;
    }
  } else if (isSSR) {
    redirectTo = homeURL;
  } else {
    redirectTo = `${window.location.pathname}${window.location.search}`;
  }

  return stringifyUrl({
    url: signInURL,
    query: {
      nextUrl: redirectTo,
    },
  });
};

const ssrLoginWithReturn = isSSR
  ? withSsrSession(loginWithReturnHere)
  : loginWithReturnHere;

const updateURLQuery = (
  router: NextRouter,
  queryParams: Record<string, any> = {},
  hash = '',
  partial = true,
  replace = true,
) => {
  const routerAction = replace ? router.replace : router.push;
  const href = isSSR
    ? ssrLocalStorage.getStore()?.href || '/'
    : window.location.href;

  const { url, query, fragmentIdentifier } = parseUrl(href, {
    parseFragmentIdentifier: true,
    ...QueryStringArrayFormat,
  });
  const newHash = hash || (partial ? fragmentIdentifier : undefined);
  const newQuery = partial ? { ...query, ...queryParams } : queryParams;

  const newURL = stringifyUrl(
    {
      url,
      query: newQuery,
      fragmentIdentifier: newHash,
    },
    QueryStringArrayFormat,
  );

  if (newURL !== href || fragmentIdentifier !== newHash) {
    routerAction(newURL, undefined, {
      scroll: false,
      shallow: true,
    });
  }
};

const getHref = () => {
  return isSSR ? ssrLocalStorage.getStore()?.href || '/' : window.location.href;
};

const getURLQuery = (href?: string) => {
  const { query } = parseUrl(href ?? getHref(), {
    ...QueryStringArrayFormat,
  });
  return query as ParsedQuery<string | boolean | number | undefined>;
};

const paramParserDate = (value: unknown) => {
  try {
    const date = typeof value === 'string' && parseISO(value);
    return date && date.toString() !== 'Invalid Date' ? date : undefined;
  } catch {
    return undefined;
  }
};

const paramFormatterString = (value: unknown) => {
  return !(value === undefined || value === null) ? `${value}` : '';
};

type LoadParamFromURLOptions<T = string | boolean | number | null | undefined> =
  {
    formatter?: (v: T) => string;
    allowedValues?: T | T[];
    validator?: (v: T) => boolean;
    parser?: (v: T) => any;
  };

const loadParamFromURL = (
  param: string,
  { formatter, allowedValues, validator, parser }: LoadParamFromURLOptions = {},
) => {
  let value = getURLQuery()[param];
  if (Array.isArray(allowedValues)) {
    // cleaning up bad values using white list
    if (Array.isArray(value)) {
      value = value
        .map((v) => (allowedValues.includes(v) ? v : undefined))
        .filter((v) => typeof v !== 'undefined');
    } else {
      value = allowedValues.includes(value) ? value : undefined;
    }
  } else if (validator || parser || formatter) {
    // or cleaning them with validator/parser
    if (Array.isArray(value)) {
      if (formatter) {
        value = value.map(formatter);
      }
      if (validator) {
        value = value.filter(validator);
      }
      if (parser) {
        value = value.map(parser).filter((v) => typeof v !== 'undefined');
      }
    } else {
      if (formatter) {
        value = formatter(value);
      }
      if (validator) {
        value = validator(value) ? value : null;
      }
      if (parser && value !== null) {
        const parsedValue = parser(value);
        value = parsedValue !== undefined ? parsedValue : null;
      }
    }
  }
  return value;
};

const getPDFExportURL = (
  pagePath: string,
  pageParams?: Record<string, any>,
  pdfOptions?: Omit<PDFConverterProps, 'pdfPath'>,
) =>
  stringifyUrl(
    {
      url: apiURLs.pdfExport,
      query: {
        ...pageParams,
        ...pdfOptions,
        pdfPath: pagePath,
      },
    },
    QueryStringArrayFormat,
  );

// NOTE: temporary backwards compatibility
const [paramValidatorGUID, paramValidatorDate] = [isGuidString, isDateString];

// Strips #hash part of URI, since it doesn't exist on server side.
// See https://github.com/vercel/next.js/issues/25202 for details.
const getNormalizedAsPath = (router: NextRouter) => {
  return router.asPath.split('#')[0];
};

export {
  apiURLs,
  forbiddenURL,
  homeURL,
  signInURL,
  resetPasswordURL,
  inviteURL,
  confirmURL,
  selectOrganizationURL,
  loginWithReturnHere,
  updateURLQuery,
  getHref,
  getURLQuery,
  getPDFExportURL,
  loadParamFromURL,
  paramValidatorGUID,
  paramValidatorDate,
  paramParserDate,
  paramFormatterString,
  ssrLoginWithReturn,
  getNormalizedAsPath,
};
