import { ParseOptions, StringifyOptions } from 'query-string';

export const MUI_LICENSE_KEY =
  '2b346ff673cd2e678a8e3d75e4a4732aTz03NzMyNixFPTE3Mjk4MDA2MjEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';
export const CDN_DOMAIN = 'organiccdn.io';

export const UI_TIMEOUT_SHORT = 100;
export const UI_TIMEOUT_NORMAL = 500;
export const UI_TIMEOUT_LONG = 1000;
export const UI_TIMEOUT_EXTRA_LONG = 2000;

// Querystring array configuration to be used across all apps
export const QueryStringArrayFormat = {
  arrayFormat: 'bracket-separator',
  arrayFormatSeparator: '|',
} as StringifyOptions | ParseOptions;
