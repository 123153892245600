/*
  This file contains common code related to application session, used both at client and server.
*/
import { gql } from 'graphql-request';

import { GuidString } from './typeGuards';
import { getRelease } from './utils';

export type Session = {
  activeOrganizationGuid: GuidString | null;
  organizations: NonNullable<MemberType['organization']>[];
  permissions: Record<string, string[]>;
  user: Omit<UserSessionDataFragment, 'members'> & {
    activeOrganizationGuid: string | null;
    isSignedIn: boolean;
    signInRelease: string;
  };
};

const getDefaultSession: () => Session = () => ({
  activeOrganizationGuid: null,
  organizations: [],
  permissions: {},
  user: {
    guid: '',
    firstName: '',
    lastName: '',
    username: '',
    profileUrl: '',
    email: '',
    pendingEmail: '',
    isSuperuser: false,
    // feature flags
    featureFlagAnalyticsTester: false,
    // non GQL extra properties
    isSignedIn: false,
    signInRelease: getRelease(),
    activeOrganizationGuid: null,
  },
});

const userSessionDataFragment = gql`
  fragment userSessionData on UserType {
    guid
    firstName
    lastName
    username
    profileUrl
    email
    pendingEmail
    isSuperuser
    # this flag enables Analytics tester features; Django and API and Hasura have a matching permission
    featureFlagAnalyticsTester
    members {
      edges {
        node {
          organization {
            guid
            name
            publisherCount
            siteCount
            sites {
              abbreviation
              color
              guid
              name
              siteLogo
            }
            useOrganicGam
          }
          sitePermissions {
            permissionCode
            siteGuid
          }
        }
      }
    }
  }
`;

const userSessionDataQuery = gql`
  ${userSessionDataFragment}
  query userSessionDataQuery($guid: UUID) {
    user(guid: $guid) {
      ...userSessionData
    }
  }
`;

export { getDefaultSession, userSessionDataFragment, userSessionDataQuery };
