import { faAngleDown as iconDropdown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faSquare as iconCheckboxUnchecked } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { faCheckSquare as iconCheckboxChecked } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faCircleXmark as iconClear } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import { faMinusSquare as iconCheckboxIndeterminate } from '@fortawesome/pro-solid-svg-icons/faMinusSquare';
import {
  blue,
  deepOrange,
  green,
  orange,
  pink,
  teal,
  amber,
} from '@mui/material/colors';
import {
  alpha,
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@mui/material/styles';

import Icon, { IconProps } from '@components/Icon';
import { debugLog } from '@helpers/utils';

import { generateFontFaceRule } from './fonts/common';
import interFont from './fonts/inter';

import type { ColorPartial } from '@mui/material/styles/createPalette';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import type {} from '@mui/lab/themeAugmentation';

// custom color palette
// from Open Color: https://yeun.github.io/open-color/
const grey = {
  50: '#fafafa',
  100: '#f1f3f5',
  200: '#e9ecef',
  300: '#dee2e6',
  400: '#ced4da',
  500: '#adb5bd',
  600: '#868e96',
  700: '#495057',
  800: '#343a40',
  900: '#212529',
  A100: '#f1f3f5', // same as 100
  A200: '#e9ecef', // same as 200
  A400: '#ced4da', // same as 400
  A700: '#495057', // same as 700
};

const primary = {
  50: '#E4E6EC',
  100: '#BBC1CF',
  200: '#8D97AF',
  300: '#5F6D8F',
  400: '#3D4E77',
  500: '#1B2F5F',
  600: '#182A57',
  700: '#14234D',
  800: '#101D43',
  900: '#081232',
  A100: '#6C87FF',
  A200: '#395DFF',
  A400: '#0633FF',
  A700: '#002BEB',
};

// ThemeOptions augmentation
declare module '@mui/material/styles' {
  interface PaletteColor {
    main: string;
    mainGradient: string;
    light: string;
    dark: string;
    contrastText: string;
  }

  interface PaletteColorOptions {
    main?: string;
    mainGradient?: string;
    light?: string;
  }

  interface PaletteOptions {
    blue?: ColorPartial;
    metric?: Record<string, ColorPartial>;
    brandColors?: {
      pureWhite: string;
      cream: string;
      electricBlue: string;
      organicBlue: string;
      night: string;
      peach: string;
      seaFoam: string;
    };
    heatMapColors?: {
      step1: string;
      step2: string;
      step3: string;
      step4: string;
    };
  }

  interface Palette {
    blue: ColorPartial;
    metric: Record<string, ColorPartial>;
    brandColors: {
      pureWhite: string;
      cream: string;
      organicBlue: string;
      night: string;
      peach: string;
      seaFoam: string;
    };
    heatMapColors: {
      step1: string;
      step2: string;
      step3: string;
      step4: string;
    };
  }

  interface ThemeOptions {
    cursors?: {
      edit: string;
      editDisabled: string;
    };
  }

  interface Theme {
    cursors: Record<string, any>;
  }
}

// @todo deprecate brandColors and delete it everywhere
const brandColors = {
  pureWhite: '#fff',
  cream: '#fdf8Ed',
  electricBlue: '#5782F2',
  organicBlue: '#1b2f5f',
  night: '#041131',
  peach: '#ffbfb7',
  seaFoam: '#8ac5c2',
};

// MUI theme options: https://mui.com/customization/theming/
const muiThemeOptions: ThemeOptions = {
  palette: {
    background: {
      default: grey[50],
      paper: brandColors.pureWhite,
    },
    blue,
    grey,
    primary: {
      main: '#5370dd',
      mainGradient:
        'linear-gradient(to right top, #d067ce, #bf68d4, #ac6ad9, #986bdc, #806ddf, #946bd9, #a569d3, #b468cc, #db67b6, #f26f9f, #fe7f8a, #ff937b)',
      light: '#E8EAF6',
      ...primary,
    },
    secondary: {
      main: '#dd5371',
    },
    text: {
      primary: grey[900],
      secondary: grey[600],
      disabled: alpha(grey[900], 0.38),
    },
    metric: {
      adsPv: pink,
      cpm: deepOrange,
      default: grey,
      filledImpressions: amber,
      pageviews: orange,
      predictive: blue,
      revenue: green,
      rpm: teal,
      unfilledImpressions: amber,
    },
    heatMapColors: {
      step1: '#f1f4fd',
      step2: '#bbc8f6',
      step3: '#899bed',
      step4: '#596fe0',
    },
    // colors from the brand style guide
    brandColors,
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: 'inherit', // since this is a variable font, we must use 'inherit' to inherit the @supports rule override
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    // heading font sizes from Type Scale (1.125 - Major Second): https://type-scale.com/?size=16&scale=1.125&text=A%20Visual%20Type%20Scale&font=Inter&fontweight=700&bodyfont=body_font_default&bodyfontweight=400&lineheight=1.75&backgroundcolor=%23ffffff&fontcolor=%23000000&preview=false
    h1: {
      fontSize: '2.566rem',
      fontWeight: 700,
      lineHeight: 1.13,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: '1.375rem',
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.3,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.57,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57,
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: 1.57,
    },
    caption: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.5,
    },
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        clearIcon: (
          <Icon
            color="inherit"
            icon={iconClear}
          />
        ),
        popupIcon: (
          <Icon
            color="inherit"
            icon={iconDropdown}
            sx={{
              fontSize: '1.125em',
            }}
          />
        ),
      },
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-endAdornment': {
            top: 'calc(50% - 16px)',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        type: 'button', // prevents unintended form submits
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeMedium: {
          paddingTop: 7,
          paddingRight: 10,
          paddingBottom: 7,
          paddingLeft: 10,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit', // IMPORTANT: This must be here or else it will use the user-agent font-family instead of the html font-family
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          marginBottom: 10,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: grey[300],
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h4',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: (
          <Icon
            color="inherit"
            icon={iconCheckboxChecked}
          />
        ),
        icon: (
          <Icon
            color="inherit"
            icon={iconCheckboxUnchecked}
          />
        ),
        indeterminateIcon: (
          <Icon
            color="inherit"
            icon={iconCheckboxIndeterminate}
          />
        ),
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: (
          <Icon
            color="inherit"
            icon={iconClear}
          />
        ),
      },
      styleOverrides: {
        deleteIconSmall: {
          fontSize: 20,
        },
        avatarMedium: {
          marginLeft: 4,
        },
        avatarSmall: {
          fontSize: '0.575rem',
          marginLeft: 3,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // ensure numbers are always fixed-width in our table using `tnum` font-feature-setting if either:
          // 1. We have a column type that displays numbers as set by ExplorerGrid (see: https://mui.com/x/react-data-grid/column-definition/#column-types), or
          // 2. Cell is right-aligned in our grid (columnType number is right-aligned).
          '& .MuiDataGrid-cell--textRight, & .MuiDataGrid-cell--columnType-number':
            {
              fontFeatureSettings: '"tnum"', // double quotes are necessary here
            },
        },
        iconSeparator: {
          padding: 0, // fix icon size since this isn't a FontAwesome icon
        },
        overlay: {
          backgroundColor: alpha(brandColors.pureWhite, 0.625), // should be same as background.paper
        },
        menuIconButton: {
          // fix icon size since these aren't FontAwesome icons
          '& svg': {
            padding: 0,
          },
        },
        sortIcon: {
          padding: 0, // fix icon size since this isn't a FontAwesome icon
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          padding: 3, // fontawesome crops images without padding, so we need to add artificial padding
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        type: 'button', // prevents unintended form submits
      },
      styleOverrides: {
        root: {
          '.MuiButton-startIcon': {
            mr: 0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: brandColors.pureWhite,
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        type: 'button', // prevents unintended form submits
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiMenu-list': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 36,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        inset: {
          paddingLeft: 36, // this must match MuiListItemIcon root minWidth
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFeatureSettings: '"tnum"', // double quotes are necessary here
          // make non-selectable datepicker days more apparent
          '&.Mui-disabled': {
            color: alpha(grey[900], 0.38), // same as palette.text.disabled
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: (iconProps: IconProps) => (
          <Icon
            {...iconProps}
            color="inherit"
            icon={iconDropdown}
            sx={{
              ...(iconProps.sx || {}),
              fontSize: '1.125em',
            }}
          />
        ),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root.Mui-checked': {
            color: 'brandColors.electricBlue',
          },
          '& .MuiButtonBase-root.Mui-checked ~ .MuiSwitch-track': {
            color: 'brandColors.electricBlue',
            opacity: 0.38,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          padding: 3, // fontawesome crops images without padding, so we need to add artificial padding
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiSelect-icon': {
            top: 'calc(50% - 13px)',
          },
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontWeight: 'inherit',
          textTransform: 'none',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0, // eliminate touch delay for mobile tooltip usage
      },
    },
    MuiTypography: {
      // see: https://mui.com/components/typography/#changing-the-semantic-element
      defaultProps: {
        variantMapping: {
          overline: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        ${generateFontFaceRule(interFont.fontFace!)}
        html {
          font-family: ${interFont.fontFamily}
        }
        @supports (font-variation-settings: normal) {
          html {
            font-family: "Inter var",${interFont.fontFamily}
          }
        }
      `,
    },
  },
  cursors: {
    edit: 'url("/cursors/edit.png") 0 16, -webkit-image-set(url("/cursors/edit.png") 1x, url("/cursors/edit-hi.png") 2x) 0 16, text',
    editDisabled:
      'url("/cursors/edit-disabled.png") 0 16, -webkit-image-set(url("/cursors/edit-disabled.png") 1x, url("/cursors/edit-disabled-hi.png") 2x) 0 16, text',
  },
};

// create a theme instance
let theme = createTheme(muiThemeOptions);

// make font sizes responsive
theme = responsiveFontSizes(theme);

// log the theme to console for easy referencing
debugLog('Active theme:', theme);

export { interFont as font, theme };
