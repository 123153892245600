import { IncomingMessage } from 'http';
import { trim } from 'lodash';

const CSRF_COOKIE_NAME = 'csrftoken'; // Django's CSRF protection cookie
const MENU_STATE_COOKIE_NAME = 'organic-primary-menu-open'; // navigation drawer state (open/closed)
const SITE_SELECTION_STATE_COOKIE_NAME = 'user-selected-sites'; // selected site statefulness

const getEnvironmentPrefix = () => {
  const match = (process.env.NEXT_PUBLIC_APP_DOMAIN || '').match(
    /(\.lcl\.|\.stg\.|\.pr\.)/,
  );
  return match ? trim(match[1], '.') : '';
};

const getCookie = (key: string, req?: IncomingMessage) => {
  // see setCookie() for explanation
  const envPrefix = getEnvironmentPrefix();
  const cookieKey = envPrefix ? `${key}-${envPrefix}` : key;

  // allow this cookie to be requested server-side
  if (req) {
    const reqCookie = (req.headers.cookie || '')
      .split(';')
      .map((v) => v.trim())
      .find((v) => v.startsWith(key));

    return reqCookie?.split('=')?.[1] || '';
  }

  // client-side
  const match =
    typeof document !== 'undefined' &&
    document.cookie.match(new RegExp(`(?:^|;)\\s*${cookieKey}=([^;]*)(?:;|$)`));
  return (match instanceof Array && match[1]) || '';
};

type CookieOptions = {
  expires?: Date | string;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: 'Strict' | 'Lax' | 'None';
};

const setCookie = (
  key: string,
  value: string,
  { expires, path, domain, secure, sameSite }: CookieOptions = {},
) => {
  /* Due to the fact we are using organic.ly and <env>.organic.ly
     domains for the app, domain-based cookie separation isn't reliable.
     Thus we are using name-based cookie separation approach. */
  const envPrefix = getEnvironmentPrefix();
  const cookieKey = envPrefix ? `${key}-${envPrefix}` : key;
  // if a particular domain is not specified, we use (dot.)domain.com to match cookie domains set by the Next.js API
  // this makes modifying and deleting cookies easier
  const cookieDomain =
    domain || process.env.NEXT_PUBLIC_APP_DOMAIN!.replace(/^[\w-]+/, '');
  let cookie = `${cookieKey}=${value}; domain=${cookieDomain}`;

  if (expires) {
    cookie +=
      expires instanceof Date
        ? `; expires=${expires.toUTCString()}`
        : `; max-age=${expires.toString()}`;
  }

  if (path) {
    cookie += `; path=${path}`;
  }

  if (secure) {
    cookie += '; secure';
  }

  if (sameSite) {
    cookie += `; SameSite=${sameSite}`;
  }

  if (typeof document !== 'undefined') {
    document.cookie = cookie;
  }
};

const loadDataByKey = (cookieName: string, key: string) =>
  JSON.parse(getCookie(cookieName) || '{}')[key];
const saveDataByKey = (cookieName: string, key: string, data: any) => {
  // preserve the data from other keys
  const existingData = JSON.parse(
    getCookie(SITE_SELECTION_STATE_COOKIE_NAME) || '{}',
  );
  setCookie(
    cookieName,
    JSON.stringify({
      ...existingData,
      [key]: data,
    }),
    { path: '/' },
  );
};

export {
  CSRF_COOKIE_NAME,
  MENU_STATE_COOKIE_NAME,
  SITE_SELECTION_STATE_COOKIE_NAME,
  setCookie,
  getCookie,
  getEnvironmentPrefix,
  loadDataByKey,
  saveDataByKey,
};
