import { font } from '@themes/default';

const FontPreload = () => (
  <>
    {font.fontFaceConfig.map(({ src }) => (
      <link
        key={src}
        as="font"
        crossOrigin="anonymous"
        href={src}
        rel="preload"
        type="font/woff2"
      />
    ))}
  </>
);

export default FontPreload;
