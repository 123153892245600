import {
  Alert,
  AlertProps,
  Snackbar,
  SnackbarCloseReason,
  SnackbarProps,
} from '@mui/material';
import { ReactNode, SyntheticEvent, useCallback, useState } from 'react';

export type ToastMessageProps = Omit<
  SnackbarProps,
  'anchorOrigin' | 'onClose'
> & {
  message?: ReactNode;
  onClose?: () => void;
  severity?: AlertProps['severity'];
};

const ToastMessage = ({
  autoHideDuration = 3000,
  message = 'Success!',
  onClose,
  open,
  severity = 'success',
  ...snackbarProps
}: ToastMessageProps) => {
  const [isOpen, setIsOpen] = useState(open ?? true); // open by default; autoHideDuration timer hides

  const handleClose = useCallback(
    (e: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
      if (reason !== 'clickaway') {
        setIsOpen(false);
        onClose?.();
      }
    },
    [onClose],
  );

  return (
    <Snackbar
      {...snackbarProps}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      open={open ?? isOpen}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastMessage;
