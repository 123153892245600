import { fetch } from 'cross-fetch';
import { ClientError } from 'graphql-request';
import { RequestDocument, Variables } from 'graphql-request/dist/types';
import { get, PropertyPath } from 'lodash';
import { NextApiRequest } from 'next';
import { QueryFunctionContext } from 'react-query/types/core/types';

import {
  getClientGenericError,
  getClientNetworkError,
  GQLError,
} from './errors';
import { extractNodes } from './graphql';
import { getApiClient } from './network';
import { logger } from '../utils';

import type { IncomingMessage } from 'http';

const handleGqlFetchError = async (e: unknown) => {
  let error = getClientGenericError();
  if (e instanceof TypeError) {
    error = getClientNetworkError();
  } else if (e instanceof ClientError && e.response.status < 500) {
    error = e.response.errors as GQLError[];
  } else if (e instanceof ClientError && e.response.status >= 500) {
    const getResponseText = e.response.text;
    logger.error({
      message: 'GQL API fetch error',
      extra: {
        httpCode: e.response.status,
        text: getResponseText ? await getResponseText() : e.response.error,
        error: JSON.stringify(e, null, 2),
      },
    });
  } else {
    // catch unknown errors for investigation
    logger.error({ error: e as Error });
  }
  return error;
};

const gqlFetcher =
  (
    query: RequestDocument,
    variables?: Variables,
    headers?: RequestInit['headers'],
    req?: IncomingMessage,
    isHasura?: boolean,
  ) =>
  async (context?: QueryFunctionContext) => {
    const { signal } = context || {};
    const gqlClient = getApiClient(req, signal, isHasura);
    try {
      const data = await gqlClient.request(query, variables, headers);
      return Promise.resolve(data);
    } catch (e: unknown) {
      // the gql request can be gracefully aborted via signal in browser
      if (
        typeof DOMException !== 'undefined' &&
        e instanceof DOMException &&
        e.name === 'AbortError'
      ) {
        return Promise.resolve(undefined);
      }
      const error = await handleGqlFetchError(e);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(error);
    }
  };

const gqlMutator =
  (mutation: RequestDocument) => async (variables?: Variables) =>
    gqlFetcher(mutation, variables)();

type InfiniteParams = {
  nodeExtractPath: PropertyPath;
  pageSize: number;
};

const gqlInfiniteFetcher =
  (
    query: RequestDocument,
    infiniteParams: InfiniteParams,
    variables?: Variables,
    headers?: RequestInit['headers'],
    req?: NextApiRequest,
  ) =>
  async ({ pageParam = 0, signal }: QueryFunctionContext) => {
    const gqlClient = getApiClient(req, signal);
    const { pageSize, nodeExtractPath } = infiniteParams;

    try {
      const data = await gqlClient.request(
        query,
        { ...variables, first: pageSize, skip: pageParam },
        headers,
      );
      return Promise.resolve({
        nodes: extractNodes(get(data, nodeExtractPath)),
        nextNumberObjects: pageParam + pageSize,
        totalNumberObjects:
          get(data, `${String(nodeExtractPath)}.pageInfo.totalObjects`) ||
          get(data, `${String(nodeExtractPath)}.pageInfo.totalCount`),
      });
    } catch (e) {
      const error = await handleGqlFetchError(e);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(error);
    }
  };

// @todo: get rid of it in favor of crossfetch's fetch()
const jsonFetcher = async (
  url: string,
  method = 'POST',
  body?: BodyInit,
  headers?: HeadersInit,
) => {
  try {
    const response = await fetch(url, {
      method,
      body: body || null,
      headers: headers || {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'same-origin',
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    }

    // generating synthetic error
    const error = new Error(response.statusText) as Record<string, any>;
    error.response = response;
    error.data = data;
    throw error;
  } catch (error: any) {
    if (!error.data) {
      error.data = { message: error.message };
    }
    throw error;
  }
};

export { jsonFetcher, gqlInfiniteFetcher, gqlMutator, gqlFetcher };
