import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';

import { getUniqueId } from '@helpers/utils';

export type FontAwesomeIcon = Pick<
  IconDefinition,
  'icon' | 'iconName' | 'prefix'
>;

export type IconProps = SvgIconProps & {
  /**
   * The full icon object imported as a <a href="https://fontawesome.com/how-to-use/javascript-api/setup/importing-icons#icon-names">lower camel-cased</a> named import from `@fortawesome/pro-solid-svg-icons` or `@fortawesome/free-brands-svg-icons`.
   */
  icon: FontAwesomeIcon;
  /**
   * Displays the icon as disabled by applying the 'disabled' color attribute to the SVG element.
   */
  disabled?: boolean;
};

const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, disabled, icon, ...other } = props;

  const {
    icon: [width, height, , , svgPathData],
  } = icon;

  // fixes a FontAwesome/MUI SVG display issue: https://mui.com/components/icons/#font-awesome
  return (
    <SvgIcon
      {...other}
      ref={ref}
      color={disabled ? 'disabled' : color}
      viewBox={`0 0 ${width} ${height}`}
    >
      {typeof svgPathData === 'string' ? (
        <path d={svgPathData} />
      ) : (
        /**
         * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
         * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
         * of a duotone icon. 40% is the default opacity.
         *
         * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
         */
        svgPathData.map((d: string, i: number) => (
          <path
            key={getUniqueId()}
            d={d}
            style={{
              opacity: i === 0 ? 0.4 : 1,
            }}
          />
        ))
      )}
    </SvgIcon>
  );
});

export default Icon;
