import { cloneDeep, ListIterateeCustom, memoize, PropertyPath } from 'lodash';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

export const getStableEmptyArray = memoize(() => []);

type Key = string | number;
export type Updater<T = unknown> = (value: T) => T;
/**
 *
 * Similar to lodash _.keyBy, but takes first only element from collection.
 *
 */
export const firstKeyBy = <T = unknown>(
  collection: T[] | null | undefined,
  iteratee: (v: T) => Key,
) =>
  (collection || []).reduce((o, v) => {
    const k = iteratee(v);
    if (!(k in o)) {
      // eslint-disable-next-line no-param-reassign
      o[k] = v;
    }
    return o;
  }, {} as Record<Key, T>);

/**
 *
 * Searches element index inside the array located at the given path;
 * Returns updated path on success, null otherwise.
 *
 */
export const getElementPath = <T>(
  data: any,
  path: PropertyPath,
  predicate?: ListIterateeCustom<T, boolean>,
) => {
  if (!path) {
    return null;
  }
  const index = findIndex(get(data, path), predicate);
  if (index === -1) {
    return null;
  }
  if (isArray(path)) {
    return [...path, `${index}`];
  }
  return `${String(path)}[${index}]`;
};

export const findAndUpdate = <T = unknown>(
  array: T[],
  predicate: ListIterateeCustom<T, boolean>,
  updater: Updater<T>,
) => {
  const index = findIndex(array, predicate);
  if (index === -1) {
    return array;
  }
  const updated = cloneDeep(array);
  updated[index] = updater(array[index]);
  return updated;
};

class StringifySet<T = unknown> extends Set<T> {
  private stringSet = new Set<string>();

  add(value: T) {
    this.stringSet.add(JSON.stringify(value));
    return super.add(value);
  }

  has(value: T) {
    return this.stringSet.has(JSON.stringify(value));
  }
}

export { StringifySet };
