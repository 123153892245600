import Script from 'next/script';

import { GuidString } from '@helpers/typeGuards';
import { isProduction } from '@helpers/utils';

type MicrosoftClarityScriptProps = {
  userGuid: GuidString;
};

const MicrosoftClarityScript = ({ userGuid }: MicrosoftClarityScriptProps) => {
  // only run in production
  if (!isProduction) return null;

  return (
    <Script
      id="microsoft-clarity-snippet"
      strategy="afterInteractive"
    >
      {`
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "jbzwvo48bi");
        clarity("set", "userId", "${userGuid}");
      `}
    </Script>
  );
};

export default MicrosoftClarityScript;
