import { GuidString } from '@helpers/typeGuards';

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID as string;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const sendPageview = (pathname: string, userGuid: GuidString): void => {
  if (window?.gtag) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: pathname,
      user_id: userGuid,
    });
  }
};

type GTagEvent = {
  action: string;
  category?: string;
  label?: string;
  value?: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const sendEvent = ({ action, category, label, value }: GTagEvent): void => {
  if (window?.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};

export { GA_TRACKING_ID, sendEvent, sendPageview };
