import { useCallback, useState } from 'react';

const useForceUpdate = () => {
  const [, setState] = useState(0);
  return {
    forceUpdate: useCallback(() => setState((s) => s + 1), []),
  };
};

export default useForceUpdate;
