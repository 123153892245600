import Script from 'next/script';

import { GA_TRACKING_ID } from '@helpers/analytics';
import { getEnvironmentPrefix } from '@helpers/cookies';
import { GuidString } from '@helpers/typeGuards';

type GoogleAnalyticsScriptProps = {
  userGuid: GuidString;
};

// @todo send organization ID as well
const GoogleAnalyticsScript = ({ userGuid }: GoogleAnalyticsScriptProps) => (
  <>
    <Script
      id="google-tag-manager-script"
      src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      strategy="afterInteractive"
    />
    <Script
      id="google-analytics-snippet"
      strategy="afterInteractive"
    >
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){ dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${GA_TRACKING_ID}', {
          page_path: window.location.pathname,
          user_id: '${userGuid}',
          cookie_prefix: '${getEnvironmentPrefix()}',
          cookie_expires: 3 * 30 * 24 * 60 * 60  // 3 months, in seconds
        });
      `}
    </Script>
  </>
);

export default GoogleAnalyticsScript;
