import { useEffect } from 'react';

import { useForceUpdate } from '@hooks';
import { toastNotificationObserver } from '@hooks/useToastNotification';

import ToastMessage from './ToastMessage';

const ToastNotifications = () => {
  const { forceUpdate } = useForceUpdate();
  const notifications = toastNotificationObserver.notifications;

  useEffect(() => {
    toastNotificationObserver.register(forceUpdate);
    return () => toastNotificationObserver.unregister(forceUpdate);
  }, [forceUpdate]);

  // will consume the queue one by one
  return notifications.length > 0 ? (
    <ToastMessage {...notifications[0]} />
  ) : null;
};

export default ToastNotifications;
