import { useCallback } from 'react';

import { getUniqueId } from '@helpers/utils';

import type { ToastMessageProps } from '@components/Notifications/ToastMessage';

type ToastNotification = ToastMessageProps & {
  // form a notification group in which a new notification will overwrite others
  notificationGroup?: string;
};

type Observer = () => void;

class ToastNotificationObserver {
  private _notifications: ToastNotification[];
  private _observers: Observer[];

  constructor() {
    this._notifications = [];
    this._observers = [];
  }

  get notifications() {
    return this._notifications;
  }

  register(observer: Observer) {
    this._observers.push(observer);
  }

  unregister(observer: Observer) {
    this._observers = this._observers.filter((o) => observer !== o);
  }

  postNotification(notification: ToastNotification) {
    const originalOnClose = notification.onClose;
    // provide clean up callback
    notification.onClose = () => {
      this._notifications = this._notifications.filter(
        (n) => notification !== n,
      );
      originalOnClose?.();
      // notify all consumers to update its notifications
      this._observers.forEach((observer) => setTimeout(observer, 0));
    };
    // generate react key property for optimization
    notification.key = notification.key ?? getUniqueId();

    // owerwrite existing notifications in the group
    if (notification.notificationGroup) {
      this._notifications = this._notifications.filter(
        (n) => notification.notificationGroup !== n.notificationGroup,
      );
    }
    this._notifications.push(notification);
    // notify all consumers to update its notifications
    this._observers.forEach((observer) => setTimeout(observer, 0));
  }
}

export const toastNotificationObserver = new ToastNotificationObserver();

const useToastNotification = () => {
  const notify = useCallback(
    (
      message?: ToastNotification['message'],
      options?: Omit<ToastNotification, 'message'>,
    ) => {
      if (message === undefined || typeof message == 'string') {
        // prevent posting duplicates
        if (
          toastNotificationObserver.notifications.findIndex(
            (n) => n.message === message,
          ) !== -1
        )
          return;
      }
      toastNotificationObserver.postNotification({ message, ...options });
    },
    [],
  );

  return { notify };
};

export default useToastNotification;
