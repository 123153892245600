import { FontConfig, generateFontFace, unicodeRange } from './common';

const inter: FontConfig = {
  fontFamily: ['Inter', 'Arial', 'sans-serif'].join(','),
  fontFaceConfig: [
    // variable fonts
    {
      fontFamily: '"Inter var"',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: '100 900',
      unicodeRange,
      fontNamedInstance: 'Regular',
      src: '/fonts/inter/Inter-roman.var.woff2?v=3.15',
    },
    {
      fontFamily: '"Inter var"',
      fontStyle: 'italic',
      fontDisplay: 'swap',
      fontWeight: '100 900',
      unicodeRange,
      fontNamedInstance: 'Italic',
      src: '/fonts/inter/Inter-italic.var.woff2?v=3.15',
    },
    // other fonts for older browsers
    {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 400,
      src: '/fonts/inter/Inter-Regular.woff2?v=3.15',
    },
    {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontDisplay: 'swap',
      fontWeight: 400,
      src: '/fonts/inter/Inter-Italic.woff2?v=3.15',
    },
    {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 500,
      src: '/fonts/inter/Inter-Medium.woff2?v=3.15',
    },
    {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontDisplay: 'swap',
      fontWeight: 500,
      src: '/fonts/inter/Inter-MediumItalic.woff2?v=3.15',
    },
    {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 600,
      src: '/fonts/inter/Inter-SemiBold.woff2?v=3.15',
    },
    {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontDisplay: 'swap',
      fontWeight: 600,
      src: '/fonts/inter/Inter-SemiBoldItalic.woff2?v=3.15',
    },
    {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 700,
      src: '/fonts/inter/Inter-Bold.woff2?v=3.15',
    },
    {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontDisplay: 'swap',
      fontWeight: 700,
      src: '/fonts/inter/Inter-BoldItalic.woff2?v=3.15',
    },
  ],
};

// The actual declaration value used for @font-face
inter.fontFace = generateFontFace(inter.fontFaceConfig);

export default inter;
