import { GraphQLError } from 'graphql-request/dist/types';
import { filter, intersection, isArray, isEmpty, isEqual } from 'lodash';

import { logger } from '../utils';

// real server-side errors
const ERROR_CODE_FORBIDDEN = 'FORBIDDEN';
const ERROR_CODE_UNAUTHORIZED = 'UNAUTHORIZED';
const ERROR_CODE_VALIDATION = 'VALIDATION_ERROR';

// synthetic client-side errors that happen during request
const ERROR_CODE_GENERIC = 'CLIENT_GENERIC';
const ERROR_CODE_NETWORK = 'CLIENT_NETWORK';

// field codes
const ALL_FIELD = '__all__';

// common error messages
const ERROR_MSG_FORBIDDEN =
  "You don't have permission to perform this action. Please contact support.";
const ERROR_MSG_NETWORK =
  'A network connection error occurred. Please try again.';
const ERROR_MSG_GENERIC =
  'An unexpected error occurred. Please try again later or contact support.';

// default (django) GQL
interface GQLExtension {
  code?:
    | typeof ERROR_CODE_FORBIDDEN
    | typeof ERROR_CODE_UNAUTHORIZED
    | typeof ERROR_CODE_VALIDATION
    | typeof ERROR_CODE_GENERIC
    | typeof ERROR_CODE_NETWORK;
  field?: typeof ALL_FIELD | string;
}

export interface GQLError extends GraphQLError {
  extensions?: GQLExtension;
}

// Hasura GQL
interface HasuraGQLExtension {
  // @todo add other codes as relevant, specifically related to authorization
  // see: https://github.com/hasura/graphql-engine/blob/master/server/lib/hasura-base/src/Hasura/Base/Error.hs
  code?: 'unexpected' | 'validation-failed';
}

export interface HasuraGQLError extends GraphQLError {
  extensions?: HasuraGQLExtension;
}

const getClientNetworkError = (): GQLError[] => [
  {
    message: ERROR_MSG_NETWORK,
    extensions: { code: ERROR_CODE_NETWORK },
  },
];

const getClientGenericError = (): GQLError[] => [
  {
    message: ERROR_MSG_GENERIC,
    extensions: { code: ERROR_CODE_GENERIC },
  },
];

export type ActionError = Record<typeof ALL_FIELD | string, string[]> & {
  // holds system errors unrelated to application logic
  genericErrors?: {
    unauthorized?: boolean;
    forbidden?: boolean;
    network?: boolean;
    generic?: boolean;
  };
};

// process default (Django) API errors
const processGQLErrors = (
  // errors, returned by the gql fetcher
  errors: GQLError[] | null,
  // path to the root node where the fields are fetched in / input provided to
  prefix: string[] = [],
  // differentiate queries and mutations
  isQuery = false,
) => {
  const gqlErrors = isArray(errors) ? errors : [];

  // expose generic GQL client and server errors
  const genericErrors: ActionError['genericErrors'] = {};
  filter(
    gqlErrors,
    (e) =>
      (e?.extensions && e?.extensions?.code !== ERROR_CODE_VALIDATION) ||
      (!e?.path && !e?.extensions),
  ).forEach((e) => {
    switch (e?.extensions?.code) {
      case ERROR_CODE_UNAUTHORIZED:
        genericErrors.unauthorized = true;
        break;
      case ERROR_CODE_FORBIDDEN:
        genericErrors.forbidden = true;
        break;
      case ERROR_CODE_NETWORK:
        genericErrors.network = true;
        break;
      case ERROR_CODE_GENERIC:
        genericErrors.generic = true;
      default:
        logger.debug({
          message: 'processGQLErrors: error without path and unknown code',
          extra: { error: JSON.stringify(e, null, 2) },
        });
        break;
    }
  });

  const isValidationError = (e: GQLError) => {
    const withinPrefix =
      Boolean(e.path) && isEqual(intersection(e.path, prefix), prefix);
    return (
      withinPrefix &&
      (e.extensions?.code === ERROR_CODE_VALIDATION || isEmpty(e.extensions))
    );
  };

  // form generic and field errors
  const fieldErrors: ActionError = {};
  filter(gqlErrors, isValidationError).forEach((e) => {
    const field = e.extensions?.field ? e.extensions.field : ALL_FIELD;
    const currentErrors = fieldErrors[field];
    if (currentErrors) {
      fieldErrors[field].push(e.message);
    } else {
      fieldErrors[field] = [e.message];
    }
  });
  if (!isEmpty(fieldErrors) && isQuery) {
    // when GQL queries return specific-fetched-field errors, we consider
    // it to be a generic error. react-query will discard such data
    genericErrors.generic = true;
    logger.debug({
      message: `Field errors in GQL query: ${prefix.join('.')}`,
      extra: { gqlErrors },
    });
  }

  if (!isEmpty(fieldErrors) && !isEmpty(genericErrors)) {
    return { ...fieldErrors, genericErrors } as ActionError;
  }
  if (!isEmpty(fieldErrors)) {
    return fieldErrors;
  }
  if (!isEmpty(genericErrors)) {
    return { genericErrors } as ActionError;
  }
  return null;
};

// process Hasura API errors
const processHasuraGQLErrors = (errors: HasuraGQLError[] | null) => {
  const gqlErrors = isArray(errors) ? errors : [];

  if (gqlErrors.length === 0) {
    return null;
  }

  // expose generic GQL client and server errors
  const genericErrors: ActionError['genericErrors'] = {};

  // @todo add cases for other codes
  gqlErrors.forEach((error) => {
    switch (error?.extensions?.code) {
      case 'unexpected':
      case 'validation-failed':
      default:
        genericErrors.generic = true;
        break;
    }
  });

  return {
    genericErrors,
  };
};

export {
  ALL_FIELD,
  ERROR_CODE_GENERIC,
  ERROR_CODE_NETWORK,
  ERROR_CODE_VALIDATION,
  ERROR_CODE_UNAUTHORIZED,
  ERROR_CODE_FORBIDDEN,
  ERROR_MSG_FORBIDDEN,
  ERROR_MSG_NETWORK,
  ERROR_MSG_GENERIC,
  getClientGenericError,
  getClientNetworkError,
  processGQLErrors,
  processHasuraGQLErrors,
};
