import { captureException, captureMessage } from '@sentry/nextjs';
import { parse } from 'query-string';

import { isBrowser, isDevelopment } from './environment';

import type { ScopeContext } from '@sentry/types';

/**
 * Log messages to console for debugging purposes
 * Usage: ?debug=true
 */
const debugLog = (...args: any[]) => {
  const outputLog =
    isBrowser &&
    parse(window.location.search, { parseBooleans: true }).debug === true;

  // only output if this is a browser and debug=true is in the URL
  if (!outputLog) return;

  // log each message to console, parsing objects to ensure data is logged at time-of (avoiding object mutations)
  args.forEach((message) => {
    console.log(
      typeof message === 'object'
        ? JSON.parse(JSON.stringify(message))
        : message,
    ); // eslint-disable-line no-console
  });
};

/* Log errors to Sentry or to console in dev and test mode. */
type LoggerProps = {
  // error to log to sentry
  error?: Error;
  // message to log to sentry
  message?: string;
  // data object to send to sentry
  extra?: ScopeContext['extra'];
  // severity level to set in sentry
  level?: ScopeContext['level'];
};

const logger = ({ error, message, extra, level = 'error' }: LoggerProps) => {
  if (isDevelopment || process.env.NEXT_PUBLIC_SKIP_SENTRY) {
    /* eslint-disable no-console */
    if (error) console.log(error, error?.stack || '');
    if (message) console.log(message);
    if (extra) console.log('Extra: ', JSON.parse(JSON.stringify(extra)));
  } else {
    const captureContext = extra ? { extra, level } : undefined;
    if (error) {
      captureException(error, captureContext);
    } else {
      captureMessage(message || 'Missing message', captureContext);
    }
  }
};

logger.debug = (props: LoggerProps) => logger({ ...props, level: 'debug' });
logger.warning = (props: LoggerProps) => logger({ ...props, level: 'warning' });
logger.error = (props: LoggerProps) => logger({ ...props, level: 'error' });

export { logger, debugLog };
