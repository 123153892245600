import { isEqual } from 'lodash';
import Router from 'next/router';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { jsonFetcher } from '@helpers/api';
import { Session, getDefaultSession } from '@helpers/session';
import { GuidString } from '@helpers/typeGuards';
import {
  apiURLs,
  confirmURL,
  inviteURL,
  loginWithReturnHere,
  resetPasswordURL,
  signInURL,
} from '@helpers/urls';
import {
  getRelease,
  isBrowser,
  isProduction,
  isRunningTest,
  isStaging,
} from '@helpers/utils';

import { SessionAPIParameters } from '../pages/api/session';

/* Adjust versions where session structure was changed */
const LAST_INCOMPATIBLE_VERSION_STAGING = '1700';
const LAST_INCOMPATIBLE_VERSION_PRODUCTION = '0.0.666';

const toInt = (version: string) => parseInt(version.replaceAll('.', ''), 10);

export type UpdateSessionOptions = {
  // switches the current organization in the session
  organizationGuid?: GuidString;
  // redirect signed-out user to sign-in form
  redirectToSignIn?: boolean;
  // reset entire session
  reset?: boolean;
};

export type SessionContextType = Session & {
  updateSession: (options?: UpdateSessionOptions) => PromiseLike<void>;
};

export const SessionContext = createContext<SessionContextType>({
  ...getDefaultSession(),
  updateSession: async () => undefined,
});

const SessionProvider = ({
  children,
  ...sessionProps
}: PropsWithChildren<Session>) => {
  // sessionRef is needed to make updateSession callback stable
  const sessionRef = useRef(sessionProps);
  const [session, setSession] = useState(sessionProps);

  const updateSession = useCallback(async (options?: UpdateSessionOptions) => {
    const {
      organizationGuid = null,
      redirectToSignIn = true,
      reset = false,
    } = options || {};
    const body: SessionAPIParameters = { signInRelease: getRelease() };
    const activeOrganizationToSet =
      organizationGuid || sessionRef.current.activeOrganizationGuid;
    if (activeOrganizationToSet)
      body.activeOrganizationGuid = activeOrganizationToSet;
    if (reset) body.resetSession = true;
    const newSession: Session = await jsonFetcher(
      apiURLs.session,
      'POST',
      JSON.stringify(body),
    );

    // we update state only when very necessary to prevent global reconciliation
    if (!isEqual(sessionRef.current, newSession)) {
      sessionRef.current = newSession;
      setSession(newSession);
    }

    // schedule redirect signed-out user
    if (
      isBrowser &&
      redirectToSignIn &&
      !sessionRef.current.user.isSignedIn &&
      !(
        window.location.pathname.includes(signInURL) ||
        window.location.pathname.includes(resetPasswordURL) ||
        window.location.pathname.includes(inviteURL) ||
        window.location.pathname.includes(confirmURL)
      )
    ) {
      setTimeout(() => {
        Router.push(loginWithReturnHere());
      }, 0);
    }
  }, []);

  // sync user session on first page load/reloads
  useEffect(() => {
    if (!isRunningTest) {
      // it triggers unnecessary page reloads with next dev server
      updateSession().then(() => null);
    }
  }, [updateSession]);

  if (isBrowser) {
    // reset user's sessions based on releases of the current sessions
    // (helpful for the new incompatible deploys)
    const signInRelease = toInt(session.user.signInRelease || '0');
    const resetStaging =
      isStaging &&
      signInRelease <= toInt(LAST_INCOMPATIBLE_VERSION_STAGING) &&
      // feature testing environments are also staging, but have its own release count
      // don't need session reset functionality there
      !window.location.host.includes('.pr.');
    const resetProduction =
      isProduction &&
      signInRelease <= toInt(LAST_INCOMPATIBLE_VERSION_PRODUCTION);
    if ((resetStaging || resetProduction) && signInRelease !== 0) {
      setTimeout(async () => {
        await updateSession({ reset: true });
      }, 200);
    }
  }

  // stabilize value to eliminate re-renderings
  const value = useMemo(
    () => ({
      ...session,
      updateSession,
    }),
    [session, updateSession],
  );

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export default SessionProvider;
