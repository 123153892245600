import { isObject } from 'lodash';

export type DateString = string & { type: 'DateString' };
export type GuidString = string & { type: 'GuidString' };
export type TimeString = string & { type: 'TimeString' };
export type ISOString = string & { type: 'ISOString' };
export type URLString = string & { type: 'URLString' };

export type FileInfo = {
  type: 'FileInfo';
  url: string;
  filename: string;
};

export const isString = (value: unknown): value is string =>
  typeof value === 'string';

export const isDate = (value: unknown): value is Date => {
  if (!(value instanceof Date)) {
    return false;
  }
  return !Number.isNaN(value.getTime());
};

export const isDateString = (value: unknown): value is DateString =>
  isString(value) && /^\d{4}-\d{2}-\d{2}$/.test(value);

export const isTimeString = (value: unknown): value is TimeString =>
  isString(value) && /^\d{2}:\d{2}:\d{2}$/.test(value);

export const isISOString = (value: unknown): value is ISOString =>
  isString(value) &&
  /^(\d{4})(?:-?W(\d+)(?:-?(\d+)D?)?|(?:-(\d+))?-(\d+))(?:[T ](\d+):(\d+)(?::(\d+)(?:\.(\d+))?)?)?(?:Z(-?\d*))?$/.test(
    value,
  );

export const isGuidString = (value: unknown): value is GuidString =>
  isString(value) && /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/.test(value);

export const isURLString = (value: unknown): value is URLString => {
  try {
    const url = new URL(value as string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
};

export const isFileInfo = (value: unknown): value is FileInfo => {
  if (!isObject(value)) return false;
  const { url, filename } = value as FileInfo;
  return isURLString(url) && isString(filename);
};
